import React from 'react'
import { OurApprovals } from '../components/OurCredentials/OurApprovals'

const OurCredentials = () => {
  return (
    <>

    <OurApprovals/>
    </>
  )
}

export default OurCredentials