

const dataSlider = [
  {
    id: 0,
    title: "",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776260/VishvasWebsite/Products/Challenging%20Record/Slideshow%20L/tcr9ymrzcheth2acf1eg.jpg",

  },
  
 
];

export default dataSlider;
