import React from 'react'
import Whyus from '../components/Products/Whyus'
import OurProducts from '../components/Products/OurProducts'
import { Contact } from '../components/Forms/contactForm'
function Products() {
  return (
   <>
   <div className='mt-20'/>
   <OurProducts/>
   <Whyus/>
   <Contact/>
   
   </>
  )
}

export default Products