

const dataSlider = [
  {
    id: 0,
    title: "",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1680014489/VishvasWebsite/SERVICES/POH%20page/x7hbeacpr1vj9dfuokdc.jpg",

  },
  
 
];

export default dataSlider;
