

const dataSlider = [
  {
    id: 0,
    title: "",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1680014486/VishvasWebsite/SERVICES/Repair%20page/ubydajazidespad8kskz.jpg",

  },
  
 
];

export default dataSlider;
