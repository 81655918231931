import React from 'react'
import { SlideShowD } from '../components/PeopleStrength/SlideShowD/SlideShowD'
import Organisation from '../components/PeopleStrength/Organisation'
import { CoreValues } from '../components/PeopleStrength/CoreValues'
const StrengthPeople = () => {
  return (
    <>
    <div className='mt-20'/>
<SlideShowD/>
<CoreValues/>
<Organisation/>
    </>
  )
}

export default StrengthPeople