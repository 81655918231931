

const dataSlider = [
  {
    id: 0,
    title: "132 27 KV 21.6 30.24 MVA Traction Power transformer",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776150/VishvasWebsite/Products/TractionPowe/SlideShowE/gjssrtzrafzjvsgswgby.jpg",

  },
  {
    id: 1,
    title: "66 KV Transformers being dispatched",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776036/VishvasWebsite/Products/TractionPowe/SlideShowE/m1j6d9otzvid0m3qvr6x.jpg",
  },
  {
    id: 2,
    title: "132 KV Transformers being dispatched",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776035/VishvasWebsite/Products/TractionPowe/SlideShowE/ndvend728lzunq8jp8bc.jpg",
  },
  {
    id: 3,
    title: "66 27 KV 21.6 30.24 MVA Traction power Transformer",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776035/VishvasWebsite/Products/TractionPowe/SlideShowE/pch61d66pq0vihaooqbv.jpg",
  },
  
 
];

export default dataSlider;
