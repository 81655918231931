

const dataSlider = [
  {
    id: 0,
    title: "VPES team undergoing regular Training",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776306/VishvasWebsite/ABOUT%20US/OUR%20PEOPLE/Slideshow%20D/zptnoze5avtsfispkatr.jpg",

  },
  {
    id: 1,
    title: "Holi Celebration at VPES",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776306/VishvasWebsite/ABOUT%20US/OUR%20PEOPLE/Slideshow%20D/zehe9skkxdg0k75m02wb.jpg",
  },
  {
    id: 2,
    title: "VPES team comissioning Transformer at site",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679850340/VishvasWebsite/ABOUT%20US/OUR%20PEOPLE/Slideshow%20D/jkslyh9vf1kbqlj70n4s.jpg",
  },
  
 
];

export default dataSlider;
