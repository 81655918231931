

const dataSlider = [
  {
    id: 0,
    title: "",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776260/VishvasWebsite/Products/Special%20transformers/esfnnwrxqg5irrclhkza.jpg",

  },
  
  
 
];

export default dataSlider;
