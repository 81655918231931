

const dataSlider = [
  {
    id: 0,
    title: "Autotransformer being dispatched with accesories",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776242/VishvasWebsite/Products/AutoTransformers/Slideshow%20F/jjjpllfe3ywzwwepp5on.jpg",

  },
  {
    id: 1,
    title: "8 MVA Autotransformers being dispatched",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776242/VishvasWebsite/Products/AutoTransformers/Slideshow%20F/yjp1gq9id86x1rdlgyti.jpg",
  },
  {
    id: 2,
    title: "Vishvas make 8 MVA Autotransformer at a 2X25KV AC SSP",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776242/VishvasWebsite/Products/AutoTransformers/Slideshow%20F/sjp93rrnid0dlnqnzzt4.jpg",
  },
  {
    id: 3,
    title: "8 MVA 55 27.5 KV 'AT' GatiShakti Project-Indian Railways",
    img:"https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776241/VishvasWebsite/Products/AutoTransformers/Slideshow%20F/a9wgltvfhk8sgyr8qcob.jpg",
  },
  
 
];

export default dataSlider;
