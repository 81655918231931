

const dataSlider = [
  {
    id: 0,
    title: "",
    img: "https://res.cloudinary.com/ddy8u7ff2/image/upload/v1679776306/VishvasWebsite/GURUKUL/lbdqqo3nb317jycgujo3.jpg",

  },
  
 
];

export default dataSlider;
